import React from "react";

function Icon({fill = 'black'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <g>
        <path
          fill={fill}
          fillRule="evenodd"
          d="M13.076 22.203l-.043-5.226c6.546-.363 9.969-3.795 9.917-10.06l-.02-2.414-1.693 1.721c-2.228 2.266-4.957 3.23-8.27 2.904l-.043-5.331-10.442 9.29 10.594 9.116zm-2.06-7.186l.024 2.796-5.521-4.75L10.96 8.22l.022 2.643.837.132c3.419.54 6.42-.054 8.948-1.777-.716 3.871-3.55 5.746-8.75 5.79l-1 .008z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
