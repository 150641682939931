import React from 'react';
import BrandContext from '../contexts/BrandContext';
import BRAND_ACTIONS from '../actionCreators/brandActions'

import {TodoForm} from '../components/todoForm';
import TodoList from '../components/TodoList/index';
import {Load} from '../Load';
import STATUS from '../constants/status';

import { getBrands ,changeStatus,onApproved} from '../mocks/brands';
import { isAdmin } from '../mocks/admin';

class TodoApp extends React.Component {
  static contextType = BrandContext;
  constructor() {
    super();
    this.state = {
      tasks: [],
      filter: "",
      status: STATUS.ALL,
      admin: false,
      load: false
    };
  }

  componentDidMount = () => {
    const { brandDispatch } = this.context;
    let component = this;
    onApproved.bind(this)(() => {
      brandDispatch({type: BRAND_ACTIONS.LOAD_SUCCESS});
      this.setState({load: false});
      this.fetchTaks();
      this.checkAdmin();
    });
    this.fetchTaks();
    this.checkAdmin();
  };

  fetchTaks = async () => {
    const { brandDispatch, brandReducer } = this.context;
    
      brandDispatch({type: BRAND_ACTIONS.REQUEST_BRANDS});
      this.setState({ load: true });
      const brands = await getBrands();
      
      this.setState({ tasks: brands, load: false });
      brandDispatch({type: BRAND_ACTIONS.SET_BRANDS, value: brands});
  };

  checkAdmin = async () => {
    const admin = await isAdmin();
    this.setState({ admin });
  };

  doneTask = (task_id) => {
    this.setState({ load: true });
    let updatedList = this.state.tasks;
    changeStatus(
      updatedList[task_id.replace("task_", "")].address,
      updatedList[task_id.replace("task_", "")].status
    )
    .then(() => this.fetchTaks())
    .catch(() => this.setState({ load: false}))
  }


  updateLocalStorage(updatedList) {
    var updatedList = JSON.stringify(updatedList);
    localStorage.setItem("tasks", updatedList);
    return true;
  }

  handleFilterInput = e => {
    this.setState({ filter: e.target.value });
  };

  filterTaskList = tasks => {
    const { filter } = this.state;
    if (!filter) {
      return tasks;
    }

    return tasks.filter(task =>
      task.text.toLowerCase().includes(filter.toLowerCase())
    );
  };

  //add changelis method
  filterStatus = () => {
    const { tasks, status } = this.state;
    
    switch(status){
      case STATUS.OTHER_BRANDS:
        return tasks.filter(task => task.status === 'passive');
      case STATUS.VOUCHED_BRANDS:
        return tasks.filter(task => task.status === 'active');
      case STATUS.ALL:
      default:
        return tasks;
    }
  };

  setStatus = status => {
    this.setState({ status });
  };

  render() {
    const tasksByStatus = this.filterStatus();
    const filteredTasks = this.filterTaskList(tasksByStatus);

    const { status } = this.state;
    let layout = (
      <div>
        <TodoForm filter={this.handleFilterInput} />
        {this.state.load && <Load />}
        <TodoList
          admin={this.state.admin}
          activeStatus={status}
          setActiveStatus={this.setStatus}
          myList={filteredTasks}
          doneTask={this.doneTask}
        />
      </div>
    );
    return (
      <div>
        <div className="content">{layout}</div>
      </div>
    );
  }
};


export default TodoApp;
