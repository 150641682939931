export default {
    SET_BRANDS: 'SET_BRANDS',
    SET_CURRENT_BRAND: 'SET_CURRENT_BRAND',
    REQUEST_BRANDS: 'REQUEST_BRANDS',
    CLEAR_CURRENT_BRAND: 'CLEAR_CURRENT_BRAND',
    REQUEST_SINGLE_BRAND: 'REQUEST_SINGLE_BRAND',
    SUCCESS_REQUEST_SINGLE_BRAND: 'SUCCESS_REQUEST_SINGLE_BRAND',

    REQUEST_ADDRESS_VOUCHES: 'REQUEST_ADDRESS_VOUCHES',
    SUCCESS_REQUEST_ADDRESS_VOUCHES: 'SUCCESS_REQUEST_ADDRESS_VOUCHES',
    CLEAR_ADDRESS_VOUCHES: 'CLEAR_ADDRESS_VOUCHES',

    SET_COMING_FROM_BRANCH: 'SET_COMING_FROM_BRANCH',

    LOAD_SUCCESS: 'LOAD_SUCCESS'
}