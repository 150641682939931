import React, { useContext , useEffect} from 'react';
import brandContext from '../../contexts/BrandContext';
import { withRouter } from 'react-router-dom';
import Table from '../../components/Table/index';
import BRAND_ACTIONS from '../../actionCreators/brandActions';

import IconButton from '../../components/IconButton'
import Header from '../../components/Header'
import ArrowIcon from '../../assets/Arrow'

import { getBrandApprovals } from '../../mocks/brands';
import './styles.scss'
import get from 'lodash.get'
import {Load} from '../../Load';

function BrandVouches({history, match}) {
  const context = useContext(brandContext);
  const { text, address, status, vouches, brandApprovals} = context.brandReducer.curentBrand;
  const { isLoading } = context.brandReducer
  useEffect(() => {
    if(!Boolean(address)){
      fetchBranchVouches()
    }
  }, []);

  const fetchBranchVouches = async () => {
    const { brandDispatch } = context;
    const { brandAddress } = match.params;
    brandDispatch({type: BRAND_ACTIONS.REQUEST_SINGLE_BRAND});
    const brandStatus = await getBrandApprovals(brandAddress);
    
    brandDispatch({type: BRAND_ACTIONS.SUCCESS_REQUEST_SINGLE_BRAND, value: brandStatus});
  }

  const getMockedData = ()  => {
    const dataObj = []
    if(brandApprovals && brandApprovals.length > 0){
      brandApprovals.forEach((approval, index) => {
        dataObj.push({index, address: approval.address, vouches: approval.vouches.length})
        })
    }
    return dataObj;
  }

  const handleAddressVouchesNavigation = async address => {
    const { brandDispatch } = context;
    const brandAddress = get(context, 'brandReducer.curentBrand.address')
    const trustedVotes  = brandApprovals.find(brand => brand.address === address)

    brandDispatch({
      type: BRAND_ACTIONS.SET_COMING_FROM_BRANCH, 
      value: {
        comingFrom: brandAddress, 
        trustedVotes: trustedVotes.vouches.length
        }
      })
    history.push(`/addressVouches/${address}`);
  }

  const itemsProps = {
    address: {
      action: (data) => handleAddressVouchesNavigation(data.target.innerText),
      styles: 'clickable-address'
    }
  }
  const data = getMockedData();

  const titles = ['index', 'address', 'vouches'];
  return (
    <div className="brand-vouche-container">
      {isLoading && <Load />}
      <IconButton Icon={ArrowIcon} onClick={history.goBack}/>
      <Header vouches={vouches} status={status} address={address} brand={text}/>
      <Table 
      data={data}
      titles={titles}
      itemsProps={itemsProps}
      />
    </div>
  )
}

export default withRouter(BrandVouches);
