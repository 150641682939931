import React from "react";
import "./styles.scss";

import get from "lodash.get";

const Table = ({ data, titles, itemsProps }) => {
  const getTableData = () => {
    const tableData = [];
    const actions = { ...itemsProps };
    if (Boolean(data.length)) {
      data.forEach((item, i) => {
        //TODO: Refactor this
        let tdClassName = "row-data";
        if (i === 0) tdClassName += " padding-top";
        if (i === data.length - 1) tdClassName += " no-border";

        const rowData = titles.map((category, i) => (
          <td key={`${item}[${i}].${category}`} className={tdClassName}>
            <span
              onClick={get(actions, `${category}.action`)}
              className={get(actions, `${category}.styles`)}
            >
              {item[category]}
            </span>
          </td>
        ));
        tableData.push(<tr key={item.address}>{rowData}</tr>);
      });
    }
    return tableData;
  };

  const tableData = getTableData();
  return (
    <div className="table-container">
      {tableData.length > 0 ? (
        <table className="table-content">
          <thead>
            <tr>
              {titles.map(title => (
                <th className="label" key={title}>
                  <div className="blue-label">{title}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{tableData}</tbody>
        </table>
      ) : (
        <div className="empty-table">
          <h1>This brand hasn't been vouched yet</h1>
        </div>
      )}
    </div>
  );
};

export default Table;
