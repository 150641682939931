import React, { useContext, useEffect } from "react";
import "./styles.scss";
import { withRouter } from "react-router-dom";
import brandContext from "../../contexts/BrandContext";
import Table from "../../components/Table/index";
import IconButton from "../../components/IconButton";
import { Load } from "../../Load";
import ArrowIcon from "../../assets/Arrow";
import Header from "../../components/Header";
import { 
  getAddressApprovals,
  changeStatus,
  getAddressVouches,
  getUserVotedBrand
} from '../../mocks/brands'
import BRAND_ACTIONS from '../../actionCreators/brandActions';
import ADDRESS_ACTIONS from '../../actionCreators/addressActions'
import get from 'lodash.get';



const titles = ["brand", "address"];

function AdressVouches({ history, match }) {
  const context = useContext(brandContext);

  useEffect(() => {
    fetchAddressData()   
  }, []);
  

  const setUpdatedBrands = (brands) => {
    const { comingFrom } = context.brandReducer.currentAddresVouches
    if(!comingFrom) {
      return brands
    };

    const updatedBrands = brands.map(currentBrand => {
      
      if(currentBrand.address === comingFrom){
        const selectedBrand = (
        <span className="parent-brand">
          {currentBrand.brand}
          <span className="tooltiptext">You came from this brand</span>
        </span>)
        return {...currentBrand, brand: selectedBrand}
      }

      return currentBrand
    })

    return updatedBrands
  }

  const handleStatusUpdate = active => {
    const { trustedVotes } = context.brandReducer.currentAddresVouches
    const { brandDispatch } = context;
    
    const updatedTrustedVotes = active
    ? trustedVotes - 1
    : trustedVotes + 1

    brandDispatch({
        type: ADDRESS_ACTIONS.SET_ADDRESS_VOUCHE_STATUS,
        value: !active
    })
    brandDispatch({
      type: ADDRESS_ACTIONS.UPDATE_TRUSTED_VOTES,
      value: updatedTrustedVotes
    })
    
  }
  
  const handleChangeStatus = async () => {
    const { brandDispatch } = context;
    const { active } = context.brandReducer.currentAddresVouches
    const addressStatus = active ? 'active' : 'passive';
    const address = match.params.address;

    brandDispatch({type: ADDRESS_ACTIONS.REQUEST_VOTE_TRUSTED})
    changeStatus(address, addressStatus)
    .then(() => {
      brandDispatch({type: ADDRESS_ACTIONS.SUCCESS_VOTE_TRUSTED})
      handleStatusUpdate(active)
      })
    .catch(() => brandDispatch({type: ADDRESS_ACTIONS.ERROR_VOTE_TRUSTED}))
  }

  
  const fetchAddressData = async () => {
    const { brandDispatch } = context;
    const address = match.params.address;

      await Promise.all([
      fetchAddressVouches(address,brandDispatch),
      setAddressVouchStatus(address,brandDispatch)
      ])
  }
  
  const fetchAddressVouches = async (address, brandDispatch) => {
    brandDispatch({ type: BRAND_ACTIONS.REQUEST_ADDRESS_VOUCHES, address: address });
    const brands = await getAddressApprovals(address);
    const trustedVotes = await getAddressVouches(address);
    
    brandDispatch({
      type: BRAND_ACTIONS.SUCCESS_REQUEST_ADDRESS_VOUCHES,
      value: { 
        brands, 
        vouches: brands.length,
        trustedVotes: get(trustedVotes, 'vouches.length')
      }
    });
  };

  const setAddressVouchStatus = async (address, brandDispatch) => {
    const teste = await getUserVotedBrand(address);
    
    brandDispatch({
      type: ADDRESS_ACTIONS.SET_ADDRESS_VOUCHE_STATUS,
      value: teste
    });


  }

  const handleBack = () => {
    const { brandDispatch } = context;
    brandDispatch({ type: BRAND_ACTIONS.CLEAR_ADDRESS_VOUCHES })
    history.goBack()
  }

  const brands = get(context,'brandReducer.currentAddresVouches.brands')
  const styledBrands = setUpdatedBrands(brands)  
  const { vouches, address, trustedVotes, active } = context.brandReducer.currentAddresVouches

  return (
    <div className="brand-vouche-container">
      {context.brandReducer.isLoading && <Load />}
      <IconButton Icon={ArrowIcon} onClick={handleBack} />
      <Header 
        addressVouches
        vouches={vouches}
        brand={address}
        trustedVotes={trustedVotes}
        handleVoteTrusted={handleChangeStatus}
        active={active}
      />
      <Table
        data={styledBrands}
        titles={titles}
      />
    </div>
  );
}

export default withRouter(AdressVouches);
