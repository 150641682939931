import React from "react";
import { ReactComponent as Logo } from "./logo.svg";
export class Header extends React.Component {
  constructor() {
    super();
  }

  render() {
    let layout = (
      <div className="member-info">
        <div className="img">
          <img src="" alt="" />
        </div>
      </div>
    );
    return (
      <div>
        {layout}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Logo id="logo" />
          <h1 className="main-title">Oprove</h1>
        </div>
      </div>
    );
  }
}
