import React from 'react'
import BoxImage from './assets/cargo.svg'

export const Load = () => (
  <div className='main-content'>
    <div className='loader-container'>
      <img src={BoxImage} className='Cargo' />
      <span className='loading-brand-inform'>loading…</span>
    </div>
  </div>
)
