import React from "react";

export class TodoForm extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        <div className="todo type1">
          <form className="input-wrapper" onSubmit={this.addTask}>
            <input
              onChange={this.props.filter}
              id="todoInput"
              type="text"
              className="add-todo"
              name="add-todo"
              placeholder="Search brand"
            />
          </form>
        </div>
      </div>
    );
  }
}
