import React from "react";
import "./styles.scss";

export default function Header({
  brand,
  address,
  status,
  vouches,
  addressVouches,
  trustedVotes = 0,
  handleVoteTrusted,
  active
}) {

  const canVouch = web3.currentProvider.selectedAddress.toLowerCase() != `${brand}`.toLowerCase();  //eslint-disable-line
  const verticalDivisionClassnameFactory = priority => {
    let className = `vertical-division ${priority} `
    if(!addressVouches) className += 'margin-left';

    return className;
  }
  return (
    <div className="brand-header">
      <div className="left-side">
        <div className="vertical-division main">
          <h3 className="title">
          {addressVouches && <span className="address-span">Address: </span>}
            {brand}
          </h3>
        </div>
        <div className={verticalDivisionClassnameFactory('secondary')}>
          <h4>
            Vouches: <span className="vouches">{vouches}</span>
          </h4>
        </div>
        
        {addressVouches && 
        <div 
        onClick={ canVouch ? handleVoteTrusted : () => {}}
        className="trusted-votes-container">
           <span className="trusted-votes">
             This brand was voted as trusted
             <span className="vouches">
              {` ${trustedVotes} `}
             </span>
             times
            </span>
            {canVouch && <button className={`vote-button ${active ? 'revoke' : ''}`}>
              {active ? 'Revoke trust' : 'vote trusted'}
            </button>}
        </div>
        }
      </div>
      {!addressVouches && (
        <div className="right-side">
          <div className={verticalDivisionClassnameFactory('main')}>
            <h3 className="brand-status">
              Status: <span className={status}>{status}</span>
            </h3>
          </div>
          <div className="vertical-division secondary">
            <h4>
              Address: <span style={{ color: "black" }}>{address}</span>
            </h4>
          </div>
        </div>
      )}
      
    </div>
  );
}
