import React, { useEffect } from 'react';

import './styles.scss';

export default function IconButton({ onClick, Icon }) {
  const setClass = (element, className) => {
    element.className = className
  }
  
  useEffect(() => {
    const button = document.getElementById('back-button');
    button.addEventListener('mouseenter', () => setClass(button, 'icon-button hovered'))
    button.addEventListener('mouseleave', () => setClass(button, 'icon-button mouse-leave'))
  }, [])
  return (
      <button 
      id="back-button" 
      className="icon-button"
      onClick={onClick}>
        <Icon/>
      </button>
  )
}
