
const devery = require('@devery/devery');
const DeveryRegistry = devery.DeveryRegistry;
const DeveryTrust = devery.DeveryTrust;
const { formatBrand } = require('../helpers/brandFormatters');
const get = require('lodash.get')

export function onApproved(callback){
    const deveryTrustClient = new DeveryTrust();
    const eventName = 'Approve';
    deveryTrustClient.__deveryTrustContract.removeAllListeners(eventName);
    if (callback) {
        
        deveryTrustClient.__deveryTrustContract.on(eventName, callback);
    }
}

/* eslint-disable */
function getAddress(){
    try{
        if(web3 && web3.currentProvider && web3.currentProvider.isMetaMask){
            return web3.eth.accounts[0];
        }
        return "";
    }
    catch(e){
        return "";
    }
}
export async function getBrands() {
    let myAddress = getAddress();
    const deveryRegistryClient = new DeveryRegistry();
    let brandsAddressList = await deveryRegistryClient.appAccountsPaginated(0,999999);
    let resolvedBrands = []
    brandsAddressList.forEach(address => {
        resolvedBrands.push(deveryRegistryClient.getBrand(address))
    });
    resolvedBrands = await Promise.all(resolvedBrands);
    //TODO: the 2 lines bellow were being used with allSettled
    // resolvedBrands = resolvedBrands.filter(p => p.status === 'fulfilled');
    // resolvedBrands = resolvedBrands.map(p => p.value)
    resolvedBrands = resolvedBrands.filter(brand => brand[0] !== "0x0000000000000000000000000000000000000000")
    const brands = resolvedBrands.map(brand => ({text:brand[2],address:brand[0]}));
    
    return Promise.all(brands.map(brand => resolveBrandStatus(brand,myAddress)))
}

async function resolveBrandStatus(brand, myAddress){
    const deveryTrustClient = new DeveryTrust()
    let brandStatus = await deveryTrustClient.getBrandApprovals(brand.address);

    const rawBrandApprovals = [] 
    let status = 'passive';

    brandStatus.forEach(addr => {
        if(addr!= "0x0000000000000000000000000000000000000000"){
            rawBrandApprovals.push(getAddressVouches(addr))
            if(addr.toLocaleLowerCase() == myAddress.toLocaleLowerCase() && status !== 'active'){
                status = 'active';
            }
        }
    })

    const brandApprovals = await Promise.all(rawBrandApprovals)
    
    return {...brand,status,vouches:brandApprovals.length, brandApprovals}
}

export async function changeStatus(address,status){
    const deveryTrustClient = new DeveryTrust();
    let transaction;
    let provider = deveryTrustClient.getProvider()
    if(status === 'active'){
        transaction = deveryTrustClient.revoke(address)
    }
    else if(status === 'passive'){
        transaction = deveryTrustClient.approve(address)
    }
    transaction = await transaction;
    await provider.provider.waitForTransaction(transaction.hash)
}

export async function getAddressVouches(address){
    const deveryTrustClient = new DeveryTrust();
    const addressVouches = await deveryTrustClient.getBrandApprovals(address)
        
    
    return {
        address,
        vouches: addressVouches
                        .filter(vouche => vouche !== '0x0000000000000000000000000000000000000000')
    }
}

export async function getBrand(address){
    const deveryRegistryClient = new DeveryRegistry();
    const unfilteredBrands = await deveryRegistryClient.getBrand(address);
    
    return unfilteredBrands;
}

export async function getUserVotedBrand(address){
    const deveryTrustClient = new DeveryTrust();
    let hasUserVotedBrand = false;

    const clientAddress = getAddress();

    const brandApprovals = await deveryTrustClient.getBrandApprovals(address)

    brandApprovals.some(brand => {
        const equalToClientAddress = brand.toLocaleLowerCase() === clientAddress.toLocaleLowerCase();
        if(equalToClientAddress){
            hasUserVotedBrand = true;
        }

        return equalToClientAddress
    })
    
    return hasUserVotedBrand
}

export async function getBrandApprovals(brandAddress){
    const deveryTrustClient = new DeveryTrust();
    const rawBrandApprovals = await deveryTrustClient.getBrandApprovals(brandAddress);
    const brand = await getBrand(brandAddress);
    
    const ApprovalRequisitions = []
    
    rawBrandApprovals.forEach(approval => {
        if(approval !== '0x0000000000000000000000000000000000000000'){
            ApprovalRequisitions.push(getAddressVouches(approval))
        }
    })

    const brandApprovals = await Promise.all(ApprovalRequisitions)
    return {
        text: brand[2], 
        address: brandAddress, 
        status: brand.active ? 'active' : 'passive', 
        vouches: brandApprovals.length, 
        brandApprovals
    };
};

export async function getFormattedBrand (deveryRegistryClient, brand) {
    const formattedBrand = await deveryRegistryClient.getBrand(brand);
    return formatBrand(formattedBrand);
}

export async function getAddressApprovals(address) {
    const deveryTrustClient = new DeveryTrust();
    const deveryRegistryClient = new DeveryRegistry();
    const approvedBrands = await deveryTrustClient.getAddressApprovals(address);
  
    if (approvedBrands.length === 0) return [];
  
    const approvedBrandsInfo = [];
    approvedBrands.forEach(brand =>
      approvedBrandsInfo.push(getFormattedBrand(deveryRegistryClient, brand))
    );
    const formattedBrands = await Promise.all(approvedBrandsInfo);
    return formattedBrands.filter(brand => brand.address !== '0x0000000000000000000000000000000000000000');
  }