import React from "react";
import BrandContext from "../../contexts/BrandContext";
import BRAND_ACTIONS from "../../actionCreators/brandActions";
import { withRouter } from "react-router-dom";
import "./styles.scss";
import STATUS from "../../constants/status";

class TodoList extends React.Component {
  static contextType = BrandContext;
  constructor() {
    super();
    this.state = { activeList: "All" };
  }

  done = e => {
    if (this.props.admin) {
      this.props.doneTask(e.target.parentNode.id);
    }
  };

  classNameGetter = status => {
    const { activeStatus } = this.props;
    return status === activeStatus ? "active" : "";
  };

  handleBrandNavigation = brandInfo => {
    const { history } = this.props;
    const { brandDispatch } = this.context;
    brandDispatch({ type: BRAND_ACTIONS.SET_CURRENT_BRAND, value: brandInfo });
    history.push(`/brandVouches/${brandInfo.address}`);
  };

  render() {
    let items_left = 0;
    const items = this.props.myList.map((elem, i) => {
      let task_id = "task_" + i;
      if (
        this.state.activeList === "All" ||
        (this.state.activeList === "Active" && elem.verified === "passive") ||
        (this.state.activeList === "Completed" && elem.status === "active")
      ) {
        if (elem.status === "active") {
          items_left++;
        }
        return (
          <li key={i} id={task_id} className={elem.status}>
            <span className="id">{i + 1}</span>
            <div className="title">
              <div
                onClick={() => this.handleBrandNavigation(elem)}
                className="brandNameWrapper"
              >
                <span className="brandName">{elem.text}</span>
              </div>
            </div>
            <span className="title address">{elem.address}</span>
            <span onClick={this.done} style={{ opacity: 0.5 }}>
              public vouches {elem.vouches}
            </span>
            <span className="type" onClick={this.done} />
          </li>
        );
      }
    });

    const { setActiveStatus, activeStatus } = this.props;
    return (
      <div>
        <div className="todo-filter type1">
          <div className="left">
            <div className="brand-counter__brand-status">
              <span>
                <b>{this.props.myList.length}</b>
                <span className="margin-left">Brands</span>
              </span>
              <span>
                <b>{items_left}</b>
                <span className="margin-left">verified brands</span>
              </span>
            </div>
          </div>
          <div className="right" id="listChanger">
            <ul>
              <li
                className={activeStatus === STATUS.ALL ? "active" : ""}
                onClick={() => setActiveStatus(STATUS.ALL)}
              >
                <span>All</span>
              </li>
              <li
                className={
                  activeStatus === STATUS.VOUCHED_BRANDS ? "active" : ""
                }
              >
                <span onClick={() => setActiveStatus(STATUS.VOUCHED_BRANDS)}>
                  Vouched Brands
                </span>
              </li>
              <li
                className={activeStatus === STATUS.OTHER_BRANDS ? "active" : ""}
              >
                <span onClick={() => setActiveStatus(STATUS.OTHER_BRANDS)}>
                  Other Brands
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="todo-list type1">
          <ul>{items}</ul>
        </div>
      </div>
    );
  }
}

export default withRouter(TodoList);
