
const devery = require('@devery/devery');
const DeveryTrust = devery.DeveryTrust;
export async function isAdmin() {
   
    // const deveryTrustClient = new DeveryTrust();
    // /* eslint-disable */
    // const isAdmin = await deveryTrustClient.isApprover(web3.currentProvider.selectedAddress);
    // /* eslint-enable */
    // return isAdmin;
    /* eslint-disable */
    try{
        return web3 && web3.currentProvider && web3.currentProvider.isMetaMask;
    }
    catch(e){
        return false;
    }
}
