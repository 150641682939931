import React, { useReducer } from "react";
import BRAND_ACTIONS from '../actionCreators/brandActions'
import ADDRESS_ACTIONS from '../actionCreators/addressActions'
import { dispatch } from "rxjs/internal/observable/pairs";


export default function reducer(state, action) {
  switch (action.type) {
    case BRAND_ACTIONS.LOAD_SUCCESS:
        return {
            ...state,
            isLoading: false,
        }
    case BRAND_ACTIONS.REQUEST_BRANDS:
        return {
            ...state,
            isLoading: true,
            fetch: false
        }
    case BRAND_ACTIONS.SET_BRANDS:
        return {
            ...state,
            isLoading: false,
            brands: action.value
        }
    case BRAND_ACTIONS.SET_CURRENT_BRAND:
        return {
            ...state,
            curentBrand: action.value
        }
    case BRAND_ACTIONS.CLEAR_CURRENT_BRAND:
        return {
            ...state,
            curentBrand: brandInitialState.curentBrand
        }
    case BRAND_ACTIONS.SUCCESS_REQUEST_SINGLE_BRAND:
        return {
            ...state,
            curentBrand: action.value,
            isLoading: false
        }
    case BRAND_ACTIONS.REQUEST_SINGLE_BRAND:
        return {
            ...state,
            isLoading: true
        }
    case BRAND_ACTIONS.SET_COMING_FROM_BRANCH:
        return {
            ...state,
            currentAddresVouches: {
                ...state.currentAddresVouches,
                comingFrom: action.value.comingFrom,
                trustedVotes: action.value.trustedVotes
            }
        }
    case BRAND_ACTIONS.REQUEST_ADDRESS_VOUCHES:
        return {
            ...state,
            isLoading: true,
            currentAddresVouches: {
                ...state.currentAddresVouches,
                address: action.address
            }
        }
    case BRAND_ACTIONS.SUCCESS_REQUEST_ADDRESS_VOUCHES:
        return {
            ...state,
            isLoading: false,
            currentAddresVouches: {
                ...state.currentAddresVouches,
                brands: action.value.brands,
                vouches: action.value.vouches,
                trustedVotes: action.value.trustedVotes
            }
        }
    case BRAND_ACTIONS.CLEAR_ADDRESS_VOUCHES:
        return {
            ...state,
            currentAddresVouches: brandInitialState.currentAddresVouches   
        }
    case ADDRESS_ACTIONS.REQUEST_VOTE_TRUSTED:
        return {
            ...state,
            isLoading: true
        }
    case ADDRESS_ACTIONS.SUCCESS_VOTE_TRUSTED:
    case ADDRESS_ACTIONS.ERROR_VOTE_TRUSTED: 
        return {
            ...state,
            isLoading: false
        }
    case ADDRESS_ACTIONS.SET_ADDRESS_VOUCHE_STATUS:
        return {
            ...state,
            currentAddresVouches: {
                ...state.currentAddresVouches,
                active: action.value
            }
        }
    case ADDRESS_ACTIONS.UPDATE_TRUSTED_VOTES:
        return {
            ...state,
            currentAddresVouches: {
                ...state.currentAddresVouches,
                trustedVotes: action.value
            }
        }
    default:   
        return state
  }
}

export const brandInitialState = {
    brands: [],
    curentBrand: {
        text: '',
        address: '',
        status: '',
        vouches: 0
    },
    currentAddresVouches: {
        active: false,
        address: '',
        comingFrom: '',
        vouches: 0,
        brands: [],
        trustedVotes: 0
    },
    fetch: true,
    isLoading: false
}
