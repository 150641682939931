import React, { useReducer } from "react";
import BrandContext from "../contexts/BrandContext";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import posed, { PoseGroup } from "react-pose";
import BrandReducer, { brandInitialState } from "../reducers/BrandReducers";

import BrandList from "./BrandList";
import BrandVouches from "./BrandVouches";
import AdressVouches from './AdressVouches';
import { Header } from "../inc/header";

import './globalStyles.scss'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 400 },
  exit: { opacity: 0 }
});
const AppRouter = () => {
  const [brandReducer, brandDispatch] = useReducer(
    BrandReducer,
    brandInitialState
  );

  
  return (
    <>
      <BrandContext.Provider value={{ brandReducer, brandDispatch }}>
        <Header />
        <Router>
          <Route render={({location}) => (
            <PoseGroup>
            <RouteContainer key={location.pathname}>
              <Switch location={location}>
                <Route component={BrandList} exact path="/" key="home" />
                <Route component={BrandVouches} path="/brandVouches/:brandAddress" key="brandVouches" />
                <Route component={AdressVouches} path="/addressVouches/:address" key="addressVouches" />
              </Switch>
            </RouteContainer>
          </PoseGroup>
          )}/>
        </Router>
      </BrandContext.Provider>
    </>
  );
};

export default AppRouter;
