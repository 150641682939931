import React from 'react';
import {render} from 'react-dom';

import AppRouter from './routes/AppRouter';
import '@metamask/legacy-web3'
setTimeout(() => {
    /* eslint-disable */
    try {
            if (web3 && web3.currentProvider && web3.currentProvider.isMetaMask) {
            // TODO: move this to deveryjs
            web3.currentProvider.enable(()=>console.log("asdkjfsadjfkjsdahf"))
            }
        } catch (e) {
            
        }
    },200);

if (!localStorage.getItem("auth")){
    let pass = prompt('Please Enter Your Password',' ');
    // let pass = "devery.ioPwd";
    if (pass!="devery.ioPwd"){
        history.go(-1);
        throw new Error('not authorized')
    }
    else{
        localStorage.setItem("auth",1)
    }
}

setTimeout(() => {
    render(<AppRouter/>, document.getElementById('appRoot'));
},250);
